/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Head from './Head'
import Ellipse from './Ellipse'
import Carousel from './Carousel'
import Steps from './Steps'
import Footer from './Footer'

const useStyles = makeStyles((theme) => ({
  carousel: {
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(1.1)',
    },
    [theme.breakpoints.up('md')]: {
      transform: 'scale(1.2)',
    },
    [theme.breakpoints.up('lg')]: {
      transform: 'scale(1.6)',
    },
    zIndex: 99,
  },
  carouselContainer: {
    marginTop: theme.spacing(20) * -1,
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(10) * -1,
    },
  },
  ellipse: {
    marginTop: theme.spacing(3) * -1,
  },
  steps: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(22),
    },
  },
}))

function LangdingPage({ link }) {
  const classes = useStyles()

  return (
    <Container disableGutters maxWidth="false" style={{ overflow: 'hidden' }}>
      <Grid container justifyContent="center">

        <Grid item xs={12}>
          <Head link={link} />
        </Grid>

        <Grid item xs={12} className={classes.ellipse}>
          <Ellipse />
        </Grid>

        <Grid item xs={12} className={classes.carouselContainer}>
          <Grid container justifyContent="center">
            <Grid item className={classes.carousel}>
              <Carousel />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={10} className={classes.steps}>
          <Steps />
        </Grid>

        <Grid item xs={12}>
          <Footer link={link} />
        </Grid>

      </Grid>
    </Container>
  )
}

LangdingPage.propTypes = {
  link: PropTypes.string.isRequired,
}

export default LangdingPage
