/* eslint-disable no-console */
export function Log(message) {
  const apiBaseURL = `${process.env.REACT_APP_API_BASE_URL}`
  if (process.env.NODE_ENV !== 'production') {
    console.log(message)
    return
  }

  fetch(`${apiBaseURL}/api/log?m=${message}`, {
    method: 'GET',
    cache: 'no-cache',
    credentials: 'include',
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('invalid \'log\' response')
      }
    })
}

export function LogView() {
  Log(`${process.env.REACT_APP_LOG_VIEW_EVENT}`)
}

export function LogClick() {
  Log(`${process.env.REACT_APP_LOG_CLICK_EVENT}`)
}
