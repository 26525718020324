import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid, Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from './Button'
import Logo from './Logo'

import BgImage from '../static/images/headbg.jpeg'

const useStyles = makeStyles((theme) => ({
  container: {
    background: `url(${BgImage}) no-repeat top center`,
    [theme.breakpoints.down('md')]: {
      backgroundPosition: '5%',
    },
    backgroundColor: 'background: rgba(0, 0, 0, 0.3)',
    position: 'relative',
    backgroundSize: 'cover',
  },
  logo: {
    marginTop: theme.spacing(5),
  },
  textContainer: {
    // marginTop: theme.spacing(15),
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(38),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(50),
    },
  },
  text: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '40px',
    fontWeight: '700',
    '& span': {
      color: '#FFD600',
    },
  },
  button: {
    marginTop: theme.spacing(10),
    paddingBottom: theme.spacing(9),
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(7),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(15),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(30),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(20),
    },
  },
  content: {
    height: '100%',
    position: 'relative',
    zIndex: '4',
  },
}))

function Head({ link }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>

      <Grid
        container
        justifyContent="center"
        className={classes.content}
      >
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            justifyContent="center"
          >
            <Grid item xs={12} className={classes.logo}>
              <Logo link={link} />
            </Grid>

            <Grid item xs={12} className={classes.textContainer}>
              <Typography className={classes.text} variant="h3">
                Turn your iPhone into a Scanner
              </Typography>
            </Grid>

            <Grid
              item
              xs={10}
              sm={8}
              md={6}
              className={classes.button}
            >
              <Button href={link}>Try For Free</Button>
            </Grid>

          </Grid>
        </Grid>
      </Grid>

    </div>
  )
}

Head.propTypes = {
  link: PropTypes.string.isRequired,
}

export default Head
